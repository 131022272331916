.header {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  background: transparent;
  color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 35px 4%;
  z-index: 9000;
}

.site-title img {
  height: 50px;
}

.header ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  font-size: 13px;
  font-weight: 800;
}

.header a {
  color: inherit;
  text-decoration: none;
}

.dashboard {
  background-color: var(--main-color);
  padding: 8px 25px;
  border-radius: 60px;
  transition: ease 0.3s;
}

.dashboard:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

@media (max-width: 1695px) {
  .site-title img {
    height: 47px;
  }
  .header ul {
    font-size: 12px;
  }
}

@media (max-width: 1380px) {
  .site-title img {
    height: 44px;
  }
  .header ul {
    font-size: 11px;
  }
}

@media (max-width: 1170px) {
  .site-title img {
    height: 38px;
  }
  .header ul {
    font-size: 10px;
  }
}

@media (max-width: 780px) {
  .site-title img {
    height: 35px;
  }
  .header ul {
    font-size: 9px;
  }
  .dashboard {
    padding: 6px 23px;
  }
}

@media (max-width: 540px) {
  .site-title img {
    height: 32px;
  }
  .header ul {
    font-size: 7px;
  }
  .dashboard {
    padding: 5px 21px;
  }
}
