.experience {
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.experiences-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.exp-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-color);
  padding: 20px;
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
  transition: ease 0.3s;
}

.exp-card:hover {
  background-color: transparent;
}

.exp-card img {
  height: 60px;
  margin-bottom: 10px;
}

.exp-card-footer p {
  font-family: "Nimbus Sans Extd D W01 Light";
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 1595px) {
  .experiences-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 790px) {
  .exp-card-footer p {
    font-size: 10px;
  }
  .experience-heading {
    font-size: 16px;
  }
  .experience-slogan {
    font-size: 14px;
  }
}

@media (max-width: 692px) {
  .exp-card-footer p {
    font-size: 8px;
  }
}

@media (max-width: 593px) {
  .experience {
    margin-bottom: 70px;
  }
}

@media (max-width: 593px) {
  .experiences-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-heading {
    font-size: 13px;
  }
  .experience-slogan {
    font-size: 11px;
  }
  .exp-card img {
    height: 50px;
  }
  .exp-card {
    padding: 10px;
  }
}
