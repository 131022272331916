.introduction {
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10%;
  height: 100dvh;
}

.introduction-title {
  font-size: 95px;
}

.slogan {
  font-family: "Nimbus Sans Extd D W01 Light";
  color: var(--main-color);
  font-size: 35px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 15px;
}

.purchase button {
  background-color: transparent;
  border: 1px solid var(--main-color);
  padding: 7px 20px;
  margin: 0;
  text-decoration: none;
  font-size: 14px;
  color: var(--secondary-color);
  border-radius: 16px;
  cursor: pointer;
  transition: ease 0.3s;
}

.purchase button:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--main-color);
}

.gradient-shadow {
  font-size: 85px;
  background: linear-gradient(135deg, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1550px) {
  .introduction-title {
    font-size: 85px;
  }
  .slogan {
    font-size: 31px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purchase button {
    padding: 7px 20px;
    font-size: 13px;
  }
}

@media (max-width: 1380px) {
  .introduction-title {
    font-size: 75px;
  }
  .slogan {
    font-size: 28px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1280px) {
  .introduction-title {
    font-size: 70px;
  }
  .slogan {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1170px) {
  .introduction-title {
    font-size: 65px;
  }
  .slogan {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purchase button {
    padding: 6px 19px;
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .introduction-title {
    font-size: 60px;
  }
  .slogan {
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purchase button {
    font-size: 12px;
  }
}

@media (max-width: 910px) {
  .introduction {
    padding: 150px 10%;
  }
  .introduction-title {
    font-size: 55px;
  }
  .slogan {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purchase button {
    font-size: 11px;
  }
}

@media (max-width: 780px) {
  .introduction-title {
    font-size: 40px;
  }
  .slogan {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .purchase button {
    padding: 5px 16px;
    font-size: 10px;
  }
}
