.bottom-menu {
  position: fixed;
  bottom: 3.5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.menu-heading {
  font-family: "Nimbus Sans Extd D W01 Light";
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.menu-sections {
  background-color: #212137;
  padding: 3px 0;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circular-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 3px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  font-size: 12px;
  cursor: pointer;
  transition: ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}

.circular-button.active-page,
.circular-button:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.large {
  width: 60px;
  border-radius: 20px;
}

.circular-button.home {
  background-image: url("../assets/menu/home.svg");
}

.circular-button.home.active-page,
.circular-button.home:hover {
  background-image: url("../assets/menu/home_hover.svg");
}

.circular-button.feat {
  background-image: url("../assets/menu/features.svg");
}

.circular-button.feat.active-page,
.circular-button.feat:hover {
  background-image: url("../assets/menu/features_hover.svg");
}

.circular-button.plat {
  background-image: url("../assets/menu/platforms.svg");
}

.circular-button.plat.active-page,
.circular-button.plat:hover {
  background-image: url("../assets/menu/platforms_hover.svg");
}

.circular-button.maps {
  background-image: url("../assets/menu/maps.svg");
}

.circular-button.maps.active-page,
.circular-button.maps:hover {
  background-image: url("../assets/menu/maps_hover.svg");
}

.circular-button.road {
  background-image: url("../assets/menu/roadmap.svg");
}

.circular-button.road.active-page,
.circular-button.road:hover {
  background-image: url("../assets/menu/roadmap_hover.svg");
}

.circular-button.succ {
  background-image: url("../assets/menu/successes.svg");
}

.circular-button.succ.active-page,
.circular-button.succ:hover {
  background-image: url("../assets/menu/successes_hover.svg");
}

.circular-button img {
  height: 70%;
}

@media (max-width: 780px) {
  .circular-button {
    width: 37px;
    height: 37px;
    margin: 0 3px;
    font-size: 10px;
  }
  .large {
    width: 40px;
  }
}

@media (max-width: 700px) {
  .bottom-menu {
    bottom: 5.5%;
  }
}

@media (max-width: 460px) {
  .circular-button {
    width: 33px;
    height: 33px;
    margin: 0 3px;
    font-size: 9px;
  }
  .large {
    width: 35px;
  }
}

@media (max-width: 380px) {
  .circular-button {
    width: 29px;
    height: 29px;
    margin: 0 3px;
    font-size: 7px;
  }
  .large {
    width: 30px;
  }
}
