.roadmap {
  color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  height: 100dvh;
}

.roadmap-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  color: var(--dark-color-text);
  cursor: pointer;
}

.roadmap-container {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 390px;
  width: 90%;
  border-radius: 20px;
  padding: 7% 6%;
}

.roadmap-heading {
  font-size: 13px;
}

.roadmap-image {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.roadmap-image img {
  width: 70%;
  height: 100%;
  max-height: 200px;
}

.roadmap-image.visible {
  opacity: 1;
}

.roadmap-text {
  font-family: "Nimbus Sans Extd D W01 Light";
  width: 90%;
  font-size: 15px;
  text-align: center;
}

.roadmap-text .custom-font {
  font-family: "Nimbus Sans Extd D W01 Light";
  font-weight: 400;
  font-size: 250%;
}

.months {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.months button {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d9d9d9;
  color: var(--dark-color-text);
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.months button.selected {
  border: 1px solid var(--main-color);
}

@media (max-width: 1200px) {
  .roadmap-text {
    font-size: 14px;
  }
  .roadmap-box {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .roadmap-container {
    width: 100%;
  }
  .roadmap-container {
    padding: 4%;
  }
  .roadmap-text {
    font-size: 12px;
  }
  .roadmap-box {
    width: 60%;
  }
}

@media (max-width: 550px) {
  .months button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 6px;
  }
  .roadmap-container {
    width: 300px;
    height: 350px;
  }
  .roadmap-image img {
    width: 70%;
    height: 100%;
    max-height: 140px;
  }
}

@media (max-width: 525px) {
  .roadmap-container {
    width: 300px;
    height: 350px;
  }
  .roadmap-text {
    font-size: 11px;
  }
}

@media (max-width: 450px) {
  .roadmap-box {
    width: 80%;
  }
  .roadmap-container {
    width: 250px;
    height: 300px;
  }
}

@media (max-width: 400px) {
  .roadmap-heading {
    font-size: 10px;
  }
}

@media (max-width: 380px) {
  .roadmap-text {
    font-size: 10px;
  }
  .roadmap-container {
    width: 225px;
    height: 275px;
  }
}

@media (max-width: 330px) {
  .roadmap-text {
    font-size: 10px;
  }
  .roadmap-container {
    width: 200px;
    height: 250px;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-right {
  animation: slideInFromRight 1s forwards;
}

.slide-in-from-top {
  animation: slideInFromTop 1s forwards;
}

.slide-in-from-left {
  animation: slideInFromLeft 1s forwards;
}
