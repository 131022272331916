.pricing {
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  height: 100dvh;
}

.pricing-heading {
  color: var(--main-color);
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.pricing-buttons {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3%;
}

.pricing-layer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monthly {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.pricing-costs {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.initial {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.each-month {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 121px;
}

.price {
  font-size: 50px;
}

.price-desc {
  font-family: "Nimbus Sans Extd D W01 Light";
  font-size: 10px;
  font-weight: 700;
}

.quarterly {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-buttons button {
  width: 170px;
  background-color: #141422;
  color: var(--secondary-color);
  margin: 0;
  border: none;
  text-decoration: none;
  font-size: 12px;
  padding: 8px 25px;
  border-radius: 60px;
  transition: ease 0.3s;
  cursor: pointer;
}

button.active {
  background-color: var(--main-color);
  color: var(--dark-color-text);
}

.monthly button:hover,
.quarterly button:hover {
  background-color: var(--main-color);
  color: var(--dark-color-text);
}

.pricing-purchase {
  margin-top: 4%;
}

.pricing-purchase button {
  background-color: transparent;
  border: 1px solid var(--main-color);
  padding: 7px 20px;
  margin: 0;
  text-decoration: none;
  font-size: 14px;
  color: var(--secondary-color);
  border-radius: 16px;
  transition: ease 0.3s;
}

.pricing-purchase.sold-out button {
  background-color: #646464;
  border: none;
}

.pricing-purchase:not(.sold-out) button:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--main-color);
  cursor: pointer;
}

.cross {
  padding: 0 5%;
}

.cross img {
  height: 150px;
}

@media (max-width: 1595px) {
  .pricing-buttons button {
    width: 150px;
  }
  .price {
    font-size: 40px;
  }
  .price-desc {
    font-size: 8px;
  }
  .cross img {
    height: 130px;
  }
  .each-month {
    min-width: 97px;
  }
  .pricing-purchase button {
    padding: 8px 21px;
    font-size: 13px;
  }
}

@media (max-width: 1420px) {
  .price {
    font-size: 30px;
  }
  .price-desc {
    font-size: 6px;
  }
  .cross img {
    height: 120px;
  }
  .each-month {
    min-width: 72px;
  }
}

@media (max-width: 1200px) {
  .pricing-buttons button {
    padding: 5px 21px;
  }
  .cross img {
    height: 100px;
  }
}

@media (max-width: 1000px) {
  .pricing-buttons button {
    width: 130px;
    font-size: 10px;
  }
}

@media (max-width: 952px) {
  .pricing-buttons button {
    padding: 5px 18px;
  }
  .each-month {
    min-width: 52px;
  }
  .pricing-purchase button {
    padding: 7px 19px;
    font-size: 11px;
  }
}

@media (max-width: 790px) {
  .pricing-buttons button {
    padding: 4px 18px;
  }
  .pricing-heading {
    font-size: 16px;
  }
  .each-month {
    min-width: 48px;
  }
  .pricing-purchase button {
    padding: 6px 18px;
    font-size: 10px;
  }
  .cross img {
    height: 90px;
  }
}

@media (max-width: 650px) {
  .pricing-heading {
    font-size: 15px;
  }
  .each-month {
    min-width: 44px;
  }
  .price {
    font-size: 25px;
  }
  .cross img {
    height: 80px;
  }
}

@media (max-width: 550px) {
  .pricing {
    padding: 60px 10%;
  }
  .pricing-heading {
    font-size: 13px;
  }

  .each-month {
    min-width: 40px;
  }
  .cross img {
    height: 70px;
  }
}

@media (max-width: 470px) {
  .pricing-heading {
    font-size: 11px;
  }
}
