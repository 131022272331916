.map {
  color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  height: 100dvh;
}

.left-side,
.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.regions-container {
  flex: 1;
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 450px;
  border-radius: 20px;
}

.world-map {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: contain;
  background-size: 100% 100%;
  border-radius: 0 0 19px 19px;
  transition: all 2s;
}

.regions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.regions-content {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.region-heading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 26px;
}

.region-text p {
  margin: 0;
  flex: 1;
  font-family: "Nimbus Sans Extd D W01 Light";
  font-size: 18px;
  font-weight: 400;
}

.flags {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flags img {
  height: 35%;
  cursor: pointer;
}

.flags img:nth-child(-n + 2) {
  margin-right: 10px;
}

.zoom-eu,
.zoom-us {
  background-size: 230% 230%;
}

.zoom-eu {
  background-position: top 5% right 50%;
}

.zoom-gb {
  background-size: 320% 320%;
  background-position: top 15% left 42%;
}

.zoom-us {
  background-size: 220% 220%;
  background-position: top 20% left -8%;
}

@media (max-width: 1280px) {
  .regions-container {
    height: 350px;
  }
}

@media (max-width: 1000px) {
  .regions-container {
    height: 300px;
  }
  .zoom-eu,
  .zoom-us {
    background-size: 300% 300%;
  }
  .zoom-eu {
    background-position: top 20% right 50%;
  }
  .zoom-gb {
    background-size: 420% 420%;
    background-position: top 25% left 42%;
  }
  .zoom-us {
    background-position: top 35% left 0%;
  }
}

@media (max-width: 800px) {
  .regions-container {
    height: 250px;
  }
}

@media (max-width: 700px) {
  .regions-container {
    height: 200px;
  }
  .region-heading {
    font-size: 18px;
  }

  .region-text p {
    font-size: 12px;
  }
}

@media (max-width: 580px) {
  .regions-container {
    height: 200px;
  }
  .region-heading {
    font-size: 16px;
  }

  .region-text p {
    font-size: 10px;
  }
  .zoom-eu,
  .zoom-us {
    background-size: 300% 300%;
  }
  .zoom-eu {
    background-position: top 20% right 50%;
  }
  .zoom-gb {
    background-size: 420% 420%;
    background-position: top 25% left 42%;
  }
  .zoom-us {
    background-position: top 35% left 0%;
  }
}

@media (max-width: 480px) {
  .regions-container {
    height: 250px;
    width: 275px;
  }
  .region-heading {
    font-size: 16px;
  }
  .region-text p {
    font-size: 10px;
  }
  .map {
    flex-direction: column;
  }
  .left-side {
    align-items: flex-end;
  }
  .right-side {
    align-items: flex-start;
    flex: 0.6;
  }
  .region-heading {
    align-items: center;
  }
  .regions-content {
    align-items: center;
    text-align: center;
  }
  .zoom-eu,
  .zoom-us {
    background-size: 275% 275%;
  }
  .zoom-eu {
    background-position: top 15% right 50%;
  }
  .zoom-gb {
    background-size: 440% 440%;
    background-position: top 20% left 42%;
  }
  .zoom-us {
    background-position: top 30% left 5%;
  }
}
