.platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  height: 100dvh;
  color: var(--secondary-color);
}

.main-container {
  position: relative;
  box-sizing: border-box;
  width: 80%;
  max-height: 100%;
  border-radius: 30px;
  padding: 3%;
  background-color: var(--dark-color);
}

.inner-rectangle.left {
  position: absolute;
  top: -5%;
  left: -12.5%;
  width: 25%;
  padding: 5px;
}

.inner-rectangle.right {
  position: absolute;
  bottom: -5%;
  right: -12.5%;
  width: 25%;
  padding: 5px;
}

.corner-text {
  color: var(--main-color);
  position: relative;
  margin: 0;
  text-align: center;
  white-space: pre-line;
  max-height: 100%;
  font-size: 100%;
}

.slick-slide {
  transition: transform 2s ease;
}

.slick-slide:not(.slick-center) {
  transform: scale(0.9);
}

.slide-image {
  cursor: pointer;
  width: 100%;
  min-height: 150px;
  object-fit: cover;
  border-radius: 15px;
}

.slide-image.with-border {
  border: 0.25px solid var(--main-color);
  border-radius: 15px;
}

.grabbing {
  cursor: grabbing;
}

.slider-modes {
  color: var(--seco-color);
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slider-mode {
  margin: 0 5px;
  cursor: pointer;
}

.new-set {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.new-text {
  color: var(--main-color);
  font-size: 70%;
}

@media (max-width: 1200px) {
  .main-container {
    width: 90%;
  }
  .corner-text {
    font-size: 13px;
  }
}

@media (max-width: 952px) {
  .corner-text {
    font-size: 11px;
  }
}

@media (max-width: 790px) {
  .corner-text {
    font-size: 9px;
  }
}

@media (max-width: 700px) {
  .corner-text {
    font-size: 8px;
  }
}

@media (max-width: 650px) {
  .main-container {
    width: 100%;
  }
}

@media (max-width: 590px) {
  .platform {
    padding: 60px 5%;
  }
  .inner-rectangle.left {
    top: -15%;
  }
  .inner-rectangle.right {
    bottom: -10%;
    right: -10.5%;
  }
}

@media (max-width: 515px) {
  .new-set {
    width: 77%;
  }
}

@media (max-width: 515px) {
  .new-set {
    width: 77%;
  }
}

@media (max-width: 453px) {
  .new-set {
    width: 88%;
  }
}

@media (max-width: 337px) {
  .new-set {
    width: 95%;
  }
}
