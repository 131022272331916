.features {
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  height: 100dvh;
}

.features-heading {
  font-family: "Nimbus Sans Extd D W01 Light";
  color: var(--main-color);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
  max-width: 60%;
}

.features-text {
  font-family: "Nimbus Sans Extd D W01 Light";
  color: #6f759d;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 40px;
  max-width: 60%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dark-color);
  padding: 20px;
  border-radius: 20px;
  height: auto;
  cursor: pointer;
  transition: ease 0.3s;
}

.grid-item:hover {
  background-color: transparent;
}

.item-heading p {
  font-family: "Nimbus Sans Extd D W01 Light";
  color: #5d5992;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  transition: color 0.3s;
  padding: 0 15%;
}

.grid-item:hover .item-heading p {
  color: var(--secondary-color);
}

.item-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item svg {
  max-height: 200px;
}

.item-footer {
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  transition: color 0.3s;
}

.grid-item:hover .item-footer {
  color: var(--main-color);
}

@media (max-height: 1000px) {
  .features {
    padding: 140px 15%;
    height: 100%;
  }
}

@media (max-width: 710px) {
  .grid-item img {
    margin: 20px;
    height: 60px;
  }
  .features-heading {
    font-size: 25px;
    max-width: 70%;
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 550px) {
  .features {
    padding: 60px 10%;
  }
  .features-heading {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .grid-item svg {
    max-height: 200px;
  }
}

@media (max-width: 458px) {
  .features-heading {
    max-width: 100%;
  }
  .features-text {
    max-width: 80%;
  }
}

@media (max-width: 360px) {
  .features-heading {
    margin-top: 70px;
  }
}
