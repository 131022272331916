:root {
  --background-color-2: #0a0a11;
  --dark-color: #151422;
  --dark-color-text: #100f1e;
  --main-color: #5d72f0;
  --secondary-color: #f3efff;
}

* {
  box-sizing: border-box;
  font-family: "Akira Expanded", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color-2);
  background: #100f1e;
}

.background-gradient {
  background: linear-gradient(var(--dark-color), #000000);
  background-image: url("./assets/animations/lightning_3.gif");
  background-size: cover;
}

.background-basic {
  background-color: #100f1e;
}

.background-lightning {
  background-image: url("./assets/animations/lightning_3.gif");
  background-size: cover;
}

.bottom-left-corner {
  position: fixed;
  bottom: 10px;
  left: 4%;
  color: #6f759d;
  font-size: 0.6vw;
  cursor: pointer;
}

.bottom-right-corner {
  position: fixed;
  bottom: 10px;
  right: 4%;
  color: #6f759d;
  font-size: 0.6vw;
  cursor: pointer;
}

.bottom-left-corner a {
  color: "inherit";
  font-family: "Nimbus Sans Extd D W01 Light";
  font-weight: 700;
  text-decoration: none;
  color: inherit;
}

.bottom-left-corner a:hover,
.bottom-right-corner:hover {
  text-decoration: underline;
}

@media (max-width: 910px) {
  .bottom-left-corner,
  .bottom-right-corner {
    font-size: 1vw;
  }
}

@media (max-width: 630px) {
  .bottom-left-corner,
  .bottom-right-corner {
    font-size: 1.2vw;
  }
}

@media (max-width: 500px) {
  .bottom-left-corner,
  .bottom-right-corner {
    font-size: 1.4vw;
  }
}

@media (max-width: 350px) {
  .bottom-left-corner,
  .bottom-right-corner {
    font-size: 1.6vw;
  }
}
