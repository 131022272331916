.successes {
  color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100%;
  background-image: url("../assets/successes_group.png");
  background-size: 100%;
  background-position: center center;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  font-size: 50px;
}

.blur-border {
  position: absolute;
  background: linear-gradient(
    #10101e 0%,
    transparent 25%,
    transparent 75%,
    #10101e 100%
  );
  pointer-events: none;
  z-index: 1;
}

.blur-border.top,
.blur-border.bottom {
  height: 100%;
  width: 100%;
}

.blur-border.top {
  top: 0;
}

.blur-border.bottom {
  bottom: 0;
}

@media (max-width: 780px) {
  .successes {
    font-size: 30px;
  }
}

@media (max-width: 460px) {
  .successes {
    font-size: 20px;
  }
}

@media (max-width: 340px) {
  .successes {
    font-size: 15px;
  }
}
